import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useState } from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Filter, Props as FilterProps } from './Filter'
import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  filters?: FilterProps[]
  languageCode: string
  offers: OfferProps[]
  title?: string
}

export const OffersList = memo(function OffersList({
  filters,
  languageCode,
  offers,
  title,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    variableWidth: true,
  }

  const [filteredCategory, setFilteredCategory] = useState('all')

  return (
    <Container>
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title dangerouslySetInnerHTML={{ __html: title }} />
        </Fade>
      ) : null}
      {filters ? (
        <Fade bottom distance="3.75rem">
          <>
            <style type="text/css">{mediaStyle}</style>
            <MediaContextProvider>
              <Media lessThan="ipadHorizontal">
                <Carousel {...settings}>
                  <Filter
                    active={filteredCategory === 'all'}
                    category="all"
                    label={useVocabularyData('all', languageCode)}
                    onClick={() => setFilteredCategory('all')}
                  />
                  {filters.map((item, index) => {
                    const category = item.category
                      ? item.category
                          .replace(/[^a-zA-Z ]/g, '')
                          .toLocaleLowerCase()
                      : undefined

                    return (
                      <Filter
                        active={filteredCategory === category}
                        key={index}
                        {...item}
                        onClick={(e) => {
                          setFilteredCategory(
                            e.target.getAttribute('data-category'),
                          )
                        }}
                      />
                    )
                  })}
                </Carousel>
              </Media>
              <Media greaterThanOrEqual="ipadHorizontal">
                <Filters dial={2} row wrap>
                  <Filter
                    active={filteredCategory === 'all'}
                    category="all"
                    label={useVocabularyData('all', languageCode)}
                    onClick={() => setFilteredCategory('all')}
                  />
                  {filters.map((item, index) => {
                    const category = item.category
                      ? item.category
                          .replace(/[^a-zA-Z ]/g, '')
                          .toLocaleLowerCase()
                      : undefined

                    return (
                      <Filter
                        active={filteredCategory === category}
                        key={index}
                        {...item}
                        onClick={(e) => {
                          setFilteredCategory(
                            e.target.getAttribute('data-category'),
                          )
                        }}
                      />
                    )
                  })}
                </Filters>
              </Media>
            </MediaContextProvider>
          </>
        </Fade>
      ) : null}
      <Fade bottom distance="3.75rem">
        <Services row wrap space="between">
          {offers.map((item, index) => {
            const category = item.category
              ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
              : undefined
            return (
              <Offer
                key={index}
                buttonLabel="find-out-more"
                className={
                  filteredCategory === category || filteredCategory === 'all'
                    ? 'visible'
                    : undefined
                }
                {...item}
              />
            )
          })}
        </Services>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 7.5rem 10vw 8.75rem;

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw 5.625rem;
  }

  @media (max-width: 1023px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 3.4375rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 0 8.454vw;
  }
`

const Filters = styled(FlexBox)``

const Carousel = styled(Slider)`
  padding: 0 calc(8.454vw - 0.3125rem);

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }
`

const Services = styled(FlexBox)`
  > a {
    width: calc(50% - 0.75rem);
    margin-top: 3.75rem;
  }

  @media (max-width: 1023px) {
    padding: 0 8.454vw;
    > a {
      width: calc(50% - 0.375rem);
    }
  }

  @media (max-width: 767px) {
    > a {
      width: 100%;
    }
  }
`

import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  category: string
  active?: boolean
  label: string
  onClick?: (e: any) => void
}

export const Filter = memo(function Filter({
  active = false,
  category,
  label,
  onClick,
}: Props) {
  if (!label) {
    return null
  }
  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {label}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  margin-bottom: 0.625rem;
  padding: 0.9375rem 1.3125rem;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
  &.active {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`
